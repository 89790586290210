import React, { useEffect, useState } from 'react'
import AOS from 'aos'
import {Link} from 'react-router-dom';
import solutionbanner from '../Assets/Images/solutions-banner.jpg';
import getstarted1 from '../Assets/Images/getstarted-1.png';
import getstarted2 from '../Assets/Images/getstart-2.png';
import getstarted3 from '../Assets/Images/getstart-3.png';
import feature1 from '../Assets/Images/feature1.jpg';
import feature2 from '../Assets/Images/feature2.jpg';
import feature3 from '../Assets/Images/feature3.jpg';
import supportimg from '../Assets/Images/support-img.jpg';
import { Helmet } from "react-helmet-async";
function Solutions(props) {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
        // window.scrollTo({top: 0, left: 0, behavior: 'instant'});
      }, []);
       var succesDeskUrl = process.env.REACT_APP_SUCCESSDESK_URL;
        const [formattedString, setFormattedString] = useState('');

        useEffect(() => {
        // Retrieve the item from localStorage
        const stringFromLocalStorage = localStorage.getItem('sourceParamssdk');

        if (stringFromLocalStorage) {
          try {
            // Parse the string into an object
            const parsedObject = JSON.parse(stringFromLocalStorage);

            // Convert the object to a string with key-value pairs
            const keyValueString = Object.entries(parsedObject)
              .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
              .join('&');

            // Set the formatted string to the state variable
            setFormattedString(keyValueString);
          } catch (error) {
            console.error('Error parsing JSON from localStorage:', error);
          }
        }
      }, []);
        if(formattedString!=''){
            succesDeskUrl = succesDeskUrl+'&'+formattedString;
        }
  return (
   <>
    <Helmet>
        <title>SuccessDesk | Solutions</title>
        <meta
          name="description"
          content="Explore our comprehensive suite of tools and services to streamline operations, enhance customer relationships, and drive success. Track all your projects in one place."
        />
        <meta
          name="keywords"
          content="SuccessDesk Solutions, SDK, Swizzle Innovations, comprehensive suite, tools and services, streamline operations, enhance customer relationships, Build Your Team, Add New Projects, Start collaborating, Track all your projects, Simplify your client relations, Promote your expertise, reach a wider audience, Dedicated customer support team"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Solutions | SuccesDesk" />
        <meta
          property="og:description"
          content="Explore our comprehensive suite of tools and services to streamline operations, enhance customer relationships, and drive success. Track all your projects in one place."
        />
      </Helmet>
   <section className="solutiions-banner-wrapper  banner-top">   
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-12 " data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="1000">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-12 order-1 order-md-0">
                                <div className="solution-banner-content">
                                    <h1>Everything You Need Is On Board</h1>
                                    <p>Explore our comprehensive suite of tools and services to streamline operations, enhance customer relationships, and drive success.</p>
                                        <div className="btn-blocker">
                                        <button className="btn btn-primary" onClick={props.openModal}>Request  Early Access</button>
                                        <Link className="btn btn-outline-primary ms-2" to='/contactus' >Contact Us</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 order-0 order-md-1 mt-md-0 mt-4">
                                <img src={solutionbanner} className="img-fluid" alt="All in One Tools and Services"/>
                                <div className="subscription-types px-xl-5 px-lg-3 px-md-0 px-4 mt-md-3 mt-3">
                                    <ul>
                                        <li><span className="material-symbols-outlined me-1">check_circle</span>Comprehensive Features</li>
                                        <li><span className="material-symbols-outlined me-1">check_circle</span>Subscription Based</li>
                                        <li><span className="material-symbols-outlined me-1">check_circle</span>24/7 Support Included</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
    </section>

    {/* banner ends */}

     {/* collabration starts  */}

    <section className="get-started">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card-body">
                        <div className="row justify-content-center">
                            <div className="col-xxl-10 col-lg-11">
                                <div className="row py-md-4 py-sm-3 py-1">
                                    <div className="col-md-12 col-12">
                                        <div className="text-center">
                                            <div className="inner-tips text-center pb-2">
                                                <p className="m-0 mt-3"><span className="material-symbols-outlined me-2">flag</span>GETTING STARTED</p>
                                            </div>
                                            <h2 className="p-2 m-0">Not sure where to start?</h2>
                                            <p className="p-2 m-0">We got you. Start customizing your SuccessDesk with these easy steps.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="project-content" data-aos="zoom-in" data-aos-anchor-placement="center-bottom" data-aos-duration="1000">
                                            <div className="project-flow">
                                                <img src={getstarted1} className="img-fluid" alt="Team Bulid"/>
                                            </div>
                                            <span className="inner-num my-3">1</span>
                                            <h6>Build Your Team</h6>
                                            <p>Start by inviting your clients and team members with whom you share projects.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="project-content" data-aos="zoom-in" data-aos-anchor-placement="center-bottom" data-aos-duration="1000">
                                            <div className="project-flow">
                                                <img src={getstarted2} className="img-fluid" alt="Add Projects"/>
                                            </div>
                                            <span className="inner-num my-3">2</span>
                                            <h6>Add Your Projects</h6>
                                            <p>Add projects that you are currently working on and include related details.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="project-content" data-aos="zoom-in" data-aos-anchor-placement="center-bottom" data-aos-duration="1000">
                                            <div className="project-flow">
                                                <img src={getstarted3} className="img-fluid" alt="Collab Now"/>
                                            </div>
                                            <span className="inner-num my-3">3</span>
                                            <h6>Start Collaborating</h6>
                                            <p>Encourage clients to share questions and comments related to their projects.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>

     {/* collabration ends  */}

     {/* features starts  */}

    <section className="main-features">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-12">
                    <div className="main-features-content">
                        <div className="inner-tips text-center pb-2">
                            <p className="m-0 mt-3"><span className="material-symbols-outlined me-2">featured_play_list</span>MAIN FEATURES</p>
                        </div>
                        <h2>Unlimited Possibilities</h2>
                        <p>Building a seamless workflow has never been so easy! Discover the unlimited 
                            possibilities that come with SuccessDesk.</p>
                    </div>
                </div>
                <div className="col-lg-6 col-9 mt-lg-5 mt-3 order-0 order-lg-0" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="1000">
                    <img src={feature1} className="img-fluid" alt="Project Track"/>
                </div>
                <div className="col-lg-6 col-12 mt-lg-5 mt-3 order-1 order-lg-1" id='projectManage'>
                    <div className="main-features-blocker">
                        <div>
                            <h3>Track all your projects in one place</h3>
                            <p className="pt-3">Simplify project management with SuccessDesk's unified platform. Track, organize, and oversee all your projects efficiently in a single, convenient location. Experience streamlined project collaboration and improved productivity with our comprehensive solution.</p>
                            <div className="timeline">
                                <div className="">
                                    <ul className="p-0">
                                        <li data-aos="zoom-in" data-aos-duration="500"><p><span className="material-symbols-outlined me-3 py-2">check</span>View project timelines.</p></li>
                                        <li data-aos="zoom-in" data-aos-duration="1000"><p><span className="material-symbols-outlined me-3 py-2">check</span>Add files related to the project.</p></li>
                                        <li data-aos="zoom-in" data-aos-duration="1500"><p><span className="material-symbols-outlined me-3 py-2">check</span>Share your thoughts and ideas as comments. </p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-12 order-3 order-lg-2" id='enhanceProject'>
                    <div className="main-features-blocker my-xxl-0 my-lg-5 my-3">
                        <div>
                            <h3>Effortlessly enhance your customer success.</h3>
                            <p className="mt-lg-0 mt-3">Our platform not only facilitates seamless communication but also features an integrated client support desk and a dedicated web portal. This ensures that users can effortlessly provide comments on inquiries, promoting transparency and guaranteeing crystal-clear project and inquiry details. Trust us for a comprehensive project management experience that prioritizes customer success through an intuitive communication platform.</p>
                            <a className="btn btn-primary" onClick={props.openModal}>Request  Early Access</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-10 order-2 order-lg-3 my-xxl-0 my-lg-5 my-3"  data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="1000">
                    <img src={feature2} className="img-fluid" alt="Client Relations Simplified"/>
                </div>
                <div id='business' className="col-lg-6 col-10 order-4 order-lg-4 my-lg-0 my-4"   data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="1000">
                    <img src={feature3} className="img-fluid" alt="Team Collaboration on Projects"/>
                </div>
                <div className="col-lg-6 col-12 order-5 order-lg-5" >
                    <div className="main-features-blocker mb-lg-0 mb-4">
                        <div>
                            <h3>Transform your business with innovative technologies</h3>
                            <p className="mt-lg-0 mt-3">Leverage cutting-edge Al innovations and custom integrations to power up your transformation. Gain access to features that will prepare you for a comprehensive business evolution, bringing together advanced technologies and tailored solutions to propel your enterprise forward.</p>
                            <a className="btn btn-primary" onClick={props.openModal}>Request  Early Access</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

     {/* features ends  */}

     {/* promotions starts  */}
    
    <section className="promotion-blocker">
        <div className="container">
            <div className="promotion-body">
                <div className="row justify-content-center align-items-center h-100">
                    <div className="col-md-11" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="1000">
                        <h3>Work together on projects with your team</h3>
                        <p>Effortlessly collaborate with your team on projects using SuccessDesk's intuitive and user-friendly platform. Share ideas, assign tasks, and achieve project goals with seamless teamwork, all within a single, integrated workspace.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

     {/* promotion ends  */}

     {/* customer support starts  */}

   <section className="support-blocker">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-5 col-12 mt-lg-5 mt-2" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="1000">
                <img src={supportimg} className="img-fluid" alt="Customer Support Team"/>
            </div>
            <div className="col-lg-7 col-12 mt-lg-5 mt-4">
                <div className="main-features-blocker px-md-5">
                    <div>
                        <h3>We're here around the clock to support you</h3>
                        <p className="pt-3">Our commitment to your success knows no bounds. With 24/7 support, we're always at your service, ready to assist, troubleshoot, and ensure your experience with our solutions is seamless, no matter the time.</p>
                        <div className="timeline">
                            <div>
                                <ul className="p-0">
                                    
                                    <li data-aos="zoom-in" data-aos-duration="1000"><p><span className="material-symbols-outlined me-3 py-2">local_activity</span>Included with all Subscriptions</p></li>
                                    <li data-aos="zoom-in" data-aos-duration="1500"><p> <span className="material-symbols-outlined me-3 py-2">headset_mic</span>Dedicated Customer Support Team</p></li>
                                    <li data-aos="zoom-in" data-aos-duration="500"><p><span class="material-symbols-outlined me-3 py-2">forum</span>Tickets & Live Chat Support</p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   </section>

   </>
  )
}

export default Solutions