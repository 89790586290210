import React, { useEffect,useState,Fragment } from 'react'
import AOS from 'aos'
import pricingbackgroud from '../Assets/Images/pricing-backgrnd.jpg';
import standard from '../Assets/Images/standrd-plan.gif';
import teampreference from '../Assets/Images/temp_preference.svg';
import launcher from '../Assets/Images/launcher.gif';
import premium from '../Assets/Images/premium.gif';
import pricingimga from '../Assets/Images/pricing-img-a.png';
import pricingimgb from '../Assets/Images/pricing-img-b.png';
import axios from "axios";
import md5 from "md5";
import { Helmet } from "react-helmet-async";
import '../Loader'
import '../Loader.css'
import { Player } from "@lottiefiles/react-lottie-player";

function Pricing(props) {
    const timestamp = Date.now();
    const apiKey = process.env.REACT_APP_API_KEY;  
    const key= timestamp+apiKey;
    const subscriptionplanURL = process.env.REACT_APP_API_URL+"api/getsubscription";
    const [toggleButton, setToggleButton] = useState("monthly");
    const [plans, setPlans] = useState([]);
    const [PlanDetails, setPlanDetails] = useState([]);
    const data = {
        'timestamp': timestamp 
      };
      const encryptedBackendKey = md5(key)
      const headers = {
       'X-Secret-Key': encryptedBackendKey,
        "Cache-Control": "no-cache",
        "Content-Type": "application/x-www-form-urlencoded",
         "Access-Control-Allow-Origin": "*",
     };
     const removeHTMLTags = (html) => {
        // Use a temporary DOM element to strip HTML tags
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
      };

    useEffect(() => {
        AOS.init();
        AOS.refresh();
        getSubscription();
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
      }, []);

      useEffect(() => {
        console.log('test');
        if (toggleButton === "yearly") {
          setPlans(PlanDetails.yearlyPlan);
          console.log(plans);
        } else {
          setPlans(PlanDetails.monthlyPlan);
          console.log(plans);
        }
      }, [toggleButton]);

      const getSubscription = async ()=>{
        try {
        //   setIsLoading(true);
          const response = await axios.post(subscriptionplanURL,data ,{ headers });
            // console.log('Response:', response.data);
            if(response.data.status === 1){
                setPlanDetails(response.data);
                setPlans(response.data.monthlyPlan);
            //   setIsLoading(false);
            }else{
              Swal.fire({
                icon: 'error',
                title: response.data.message,
                // text: error,
                timer: 3000,
              })
            }
        } catch (error) {
            console.error('Error:', error);
        }
      }

  return (
   <>
    <Helmet>
        <title>SuccessDesk | Pricing</title>
        <meta
          name="description"
          content="Explore our pricing options and choose the plan that best suits your needs and budget. SuccessDesk offers flexible pricing plans tailored to your business requirements."
        />
        <meta
          name="keywords"
          content="SuccessDesk Pricing, SDK, Swizzle Innovations,  Ideal Pricing Plan, Standard plan, Perfect plan for starters, flexible pricing plans"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Pricing | SuccesDesk" />
        <meta
          property="og:description"
          content="Explore our pricing options and choose the plan that best suits your needs and budget. SuccessDesk offers flexible pricing plans tailored to your business requirements."
        />
      </Helmet>
    {/* <section className="pricing-banner-wrapper  banner-top">   
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-12" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="1000">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-12 order-1 order-md-0">
                            <div className="pricing-banner-content">
                                <h1>Select Your Ideal Pricing Plan</h1>
                                <p>Explore our pricing options and choose the plan that best suits your needs and budget.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 order-0 order-md-1 mt-md-0 mt-4">
                            <img src={pricingbackgroud} className="img-fluid" alt="Choose Ideal Pricing Plan"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    </section> */}
    <section className=" plantypes-blocker banner-top">

      <div className='pricing-bg'>
      <div className="container">
        <div className="plantypes-content ">
          <h2>Pricing plans that suits your needs</h2>
          <p className="pricing-p py-2">SuccessDesk offers flexible pricing plans tailored to your business requirements. 
Whether you're a start-up on a budget or a large enterprise seeking comprehensive solutions, we have the right plan for you.</p>
        </div>
        <img className='pricingimga vert-move  img-fluid' src={pricingimga}  alt="" />
        <img className='pricingimgb vert-move img-fluid' src={pricingimgb} alt="" />
      </div>
      </div>
  
      <div className="container-fluid cutm-fluid px-5">
      <div className="plans">
              
              
            
              <div className="row px-md-3 px-2">
                

                  <div className="col-xl-3 col-lg-12 p-0">
                    <div className="plan-type-align border-paid top-style"> 
                    
                        <div className="d-flex justify-content-center">
                          <Player
                              src="https://lottie.host/675c6048-9b7c-4251-b7e7-c5a28edac5ee/683VeeFrAi.json"
                            background="transparent"
                            speed="1"
                            style={{ width: 90 + "px", height: 90 + "px" }}
                            loop
                            autoplay
                          ></Player>
                        </div>
                        <h2>Standard</h2>
                        {/* <p>{plans[0]?.description}</p> */}
                        <p className="plans-content ">Ideal for small teams and startups for essential project management features.</p>
                       
                        <h3 className="text-primary d-flex justify-content-center align-items-center" >$3
                        <div class="wrapper" >
                        <i class="fa-solid fa-circle-info"></i>
                            <div class="tooltip">$4.99 when paid monthly.</div>
                          </div>
                        </h3>

                        <p className="pb-4 month-plane-p">
                        Per User / Per Month Paid Annually.
                        </p>
                        
                        {/* <p>{plans[0]?.description}</p> */}
                        <ul>
                        <li>
                            <div className="done-item">
                            <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>For up to 10 Users</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                            <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Unlimited Projects</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                            <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>5 GB Storage</p>
                            </div>
                          </li>
                          </ul>
                        
                           <div><button className="btn btn-primary w-100" onClick={props.openModal}>Request  Early Access</button></div> 
                            
                    
                          <ul className='mt-3'>
                          <li>
                            <div className="done-item">
                              <p><strong>Plan Features</strong></p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Release Planning</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Milestones</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Project Calendar</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Task Estimates</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Team Assignment</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Time Tracking</p>
                            </div>
                          </li>   
                        
                                 
                          {/*                         
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span> */}
                              {/* <span>10 GB Free</span> */}
                              {/* <span>
                                {covertToGB(plans[0]?.available_storage)}
                              </span> */}
                            {/* </div>
                          </li> */}
                         
                        </ul>
                     
                    </div>
                  </div>

                  
                  <div className="col-xl-3 col-lg-12 p-0">
                    <div className="plan-type-align border-paid ">
                    
                        <div className="d-flex justify-content-center">
                          <button className="btn btn-light text-primary recmd-btn">
                            <span className="material-symbols-outlined text-primary">
                              temp_preferences_custom
                            </span>
                            Recommended
                          </button>
                        </div>
                        <div className="pro-plan">
                          <div className="plans-img">
                            <div className="d-flex justify-content-center">
                              <Player
                                src="https://lottie.host/31c4aee2-07a6-4dc0-828e-aa0f631a2b3c/fYkcbLAWrK.json"
                                background="transparent"
                                speed="1"
                                style={{ width: 90 + "px", height: 90 + "px" }}
                                loop
                                autoplay
                              ></Player>
                            </div>
                            <h2>Professional</h2>
                            <p className="plans-content ">For growing teams that need advanced project management features.</p>

                           
                             <h3 className="text-primary d-flex justify-content-center align-items-center"> $6
                             <div class="wrapper" >
                            <i class="fa-solid fa-circle-info"></i>
                            <div class="tooltip">$7.99 when paid monthly.</div>
                          </div>
                             </h3>
                            <p className="pb-4 month-plane-p">
                              Per User / Per Month
                              Paid Annually.
                            </p>
                            
                            {/* <p>{plans[1]?.description}</p> */}
                          </div>

                          <ul>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>For up to 50 Users</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Unlimited Projects</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>50 GB Storage</p>
                            </div>
                          </li>
                          </ul>

                     
                            <div>
                            <button className="btn btn-primary w-100" onClick={props.openModal}>Request  Early Access</button>
                            </div>
                       
                          <ul class="mt-3">
                          <li>
                            <div className="done-item">
                              <p><strong>All Features in Standard +</strong></p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Gantt Charts</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Kanban Views</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Test Case Management</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Activity History</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Custom Labels</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Custom Statuses</p>
                            </div>
                          </li>
                          
                        
                          {/* <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span><b>Product Partner: Access to all new features without additional cost.</b></span>
                            </div>
                          </li> */}
                           
                            {/* <li>
                              <div className="done-item">
                                <span className="material-symbols-outlined">
                                  done
                                </span>
                                <span>
                                  {covertToGB(plans[1]?.available_storage)}
                                </span>
                              </div>
                            </li> */}
                          </ul>
                        </div>
                     
                    </div>
                  </div>

                  <>
                  <div className="col-xl-3 col-lg-12 p-0">
                    <div className="plan-type-align border-paid top-style">
                   
                        <div className="enterprise-text">
                          <div className="d-flex justify-content-center">
                            <Player
                              src="https://lottie.host/79fd23d1-5302-41e6-babc-f8f776548732/LbLGiUb8uE.json"
                              background="transparent"
                              speed="1"
                              style={{ width: 90 + "px", height: 90 + "px" }}
                              loop
                              autoplay
                            ></Player>
                          </div>
                          <h2>Premium</h2>
                          <p className="plans-content ">For teams that need features designed for customer success and team management.</p>
                    
                             <h3 className="text-primary d-flex justify-content-center align-items-center"> $9
                             <div class="wrapper" >
                            <i class="fa-solid fa-circle-info"></i>
                            <div class="tooltip">$10.99 when paid monthly.</div>
                          </div>
                             </h3>
                            <p className="pb-4 month-plane-p">
                              Per User / Per Month
                              Paid Annually.
                            </p>
                           
                            {/* <p>{plans[2]?.description}</p> */}
                          </div>

                          <ul>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Unlimited Users</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Unlimited Projects</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>100 GB Storage</p>
                            </div>
                          </li>
                          </ul>
                   
                            <div >
                            <button className="btn btn-primary w-100" onClick={props.openModal}>Request  Early Access</button>
                            </div>
                      
                       <ul className='mt-3'>
                        <li>
                            <div className="done-item">
                              <p><strong>All Features in Premium +</strong></p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Web Portal for Clients</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Integrated Client Support Desk</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Organization Charts</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Team Member Profiles</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Team Recognition</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Timesheets & Approvals</p>
                            </div>
                          </li>
                         
                         
                          {/* <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span><b>Product Partner: Access to all new features without additional cost.</b></span>
                            </div>
                          </li> */}
                          
                          {/* <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>
                                {covertToGB(plans[2]?.available_storage)}
                              </span>
                            </div>
                          </li> */}
                          
                        </ul>
                   
                    </div>
                  </div>
                  
                  <div className="col-xl-3 col-lg-12 p-0">
                    <div className="plan-type-align top-style">
                    
                        <div className="enterprise-text">
                          <div className="d-flex justify-content-center">
                          <Player
                              src="https://lottie.host/6705101c-bb4a-4487-b8ed-05020c91cd56/68S0nvDZ0a.json"
                              background="transparent"
                              speed="1"
                              style={{ width: 90 + "px", height: 90 + "px" }}
                              loop
                              autoplay
                            ></Player>
                          </div>
                          <h2 className="">Enterprise</h2>
                          <p className="plans-content ">Leverage cutting-edge AI innovations and custom integrations to power up you business transformation.</p>
                          <p className='enterprise-p'>Contact us for custom pricing on our enterprise plan.</p>
                          <ul>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Unlimited Users</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Unlimited Projects</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Unlimited Storage</p>
                            </div>
                          </li>
                          </ul>
                          
                            <div >
                            <button className="btn btn-primary w-100" onClick={props.openModal}>Request  Early Access</button>
                            </div>
                        <ul className='mt-3'>
                        <li>
                            <div className="done-item">
                              
                              <p><strong>All Platform Features +</strong> </p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>AI-Powered Insights</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>White Label Branding</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Custom Services Integrations</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>Custom Workflow Implementation</p>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <p>API Access</p>
                            </div>
                          </li>  
                          </ul>                        

                          {/* <p className="">
                            {plans[2].tenure_type_id === 1
                              ? "yearly"
                              : "Monthly"}
                          </p> */}
                          {/* <button className="btn btn-primary text-white enterprise-btn">Upgrade Plan</button> */}
                        </div>
                        {/* <ul>
                         
                        
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>
                                {covertToGB(plans[2]?.available_storage)}
                              </span>
                            </div>
                          </li>
                          
                        </ul> */}
                    
                    </div>
                  </div>

                  
                  </>
              </div>
          

                {/* {plans ? (
                    plans.map((item) => (

                    <div className="col-lg-4 col-md-7 col-sm-10 col-11 my-lg-0 my-3" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-delay="150">
                        <div className={item.subscription_name === 'Professional' ? "pro-plans h-100" : "normal-plans h-100"}>
                            <div className="card-body text-center mb-3">
                           
                                <div className="recomnd-plan mb-3">
                                {item.subscription_name === 'Professional' && (
                                <span>
                                    <img src={teampreference} className="img-fluid me-2" alt="recommded-plan" />Recommended
                                </span>
                                 )}
                                </div>
                           
                                <div>
                                {/* Use if condition to set the image source based on subscription_name *
                                {item.subscription_name === 'Professional' && (
                                <img src={launcher} className="animation-plan" alt="professional-plan" />
                                )}
                                {item.subscription_name === 'Standard' && (
                                <img src={standard} className="animation-plan" alt="Standard Plan" />
                                )}
                                {item.subscription_name === 'Premium' && (
                                <img src={premium} className="animation-plan" alt="premium-plan" />
                                )}
                                </div>
                                <h5 className="pt-2">{item.subscription_name}</h5>
                                <p className="pb-3">{item.description}</p>
                                <h6 className="">${item.amount}</h6>
                                <p className="pb-2">
                                {plans[0]?.tenure_type_id === 1
                                    ? "Yearly"
                                    : "Monthly"}
                                </p>
                                {/* <button className="btn btn-primary">Choose Plan</button> 
                            </div>
                            <ul className="check-items pb-3">
                            {/* Parse HTML string and map over extracted li elements 
                            {Array.from(new DOMParser().parseFromString(item.short_description, 'text/html').body.childNodes).map((node, index) => {
                                if (node.nodeType === Node.ELEMENT_NODE && node.tagName.toLowerCase() === 'li') {
                                return (
                                    <li key={index} data-aos="zoom-in" data-aos-duration="500">
                                    <p><span className="material-symbols-outlined me-3">check</span>{removeHTMLTags(node.innerHTML)}</p>
                                    </li>
                                );
                                }
                                return null; // Skip non-element nodes
                            })}
                                {/* <li data-aos="zoom-in" data-aos-duration="500"><p><span className="materialy-symbols-outlined me-3">check</span>Team Management</p></li>
                                <li data-aos="zoom-in" data-aos-duration="1000"><p><span className="material-symbols-outlined me-3">check</span>Email Marketing Tools</p></li>
                                <li data-aos="zoom-in" data-aos-duration="1500"><p><span className="material-symbols-outlined me-3">check</span>Project Management</p></li>
                                <li data-aos="zoom-in" data-aos-duration="2000"><p><span className="material-symbols-outlined me-3">check</span>Enquiry Reporting</p></li> 
                            </ul>

                        </div>
                    </div>
                    ))
                )  : (
                    <div className="loader">
                      {/* <img src={loader}  alt="arrow"alt='loader'/> 
                      <div className="line line-1"></div>
                      <div className="line line-2"></div>
                      <div className="line line-3"></div>
                      <div className="line line-4"></div>
                      <div className="line line-5"></div>
                    </div>
                  )} */}
                    {/* <div className="col-lg-4 col-md-7 col-sm-10 col-11 my-lg-0 my-5" data-aos="fade-up" data-aos-duration="500" data-aos-delay="250">
                        <div className="pro-plans py-3">
                            <div className="card-body text-center mb-3">
                                <div className="recomnd-plan mb-3">
                                    <span><img src={teampreference} className="img-fluid me-2" alt="recommded-plan"/>Recommended</span>
                                </div>
                                <div>
                                    <img src={launcher} className="animation-plan" alt="pro-plan"/>
                                </div>
                               
                                <h5 className="pt-2">Professional</h5>
                                <p className="pb-3">Perfect plan for teams</p>
                                <h6 className="">$39.99</h6>
                                <p className="pb-2">Monthly</p>
                               
                            </div>
                            <ul className="check-items">
                                <li data-aos="zoom-in" data-aos-duration="500"><p><span className="material-symbols-outlined me-3">check</span>All Standard Plan Features</p></li>
                                <li data-aos="zoom-in" data-aos-duration="1000"><p><span className="material-symbols-outlined me-3">check</span>Unlimited Projects</p></li>
                                <li data-aos="zoom-in" data-aos-duration="1500"><p><span className="material-symbols-outlined me-3">check</span>Custom Reporting</p></li>
                                <li data-aos="zoom-in" data-aos-duration="2000"><p><span className="material-symbols-outlined me-3">check</span>Admin Tools</p></li>
                            </ul>

                        </div>
                    </div> */}
                    {/* <div className="col-lg-4 col-md-7 col-sm-10 col-11"  data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-delay="350">
                        <div className="normal-plans py-3">
                            <div className="card-body text-center mb-3">
                                <div className="recomnd-plan mb-3">
                                </div>
                                <div>
                                    <img src={premium} className="animation-plan" alt="premium-plan"/>
                                </div>
                                <h5 className="pt-2">Premium</h5>
                                <p className="pb-3">Perfect plan for companies</p>
                                <h6 className="">$149.99</h6>
                                <p className="pb-2">Monthly</p>
                            </div>
                            <ul className="check-items">
                                <li data-aos="zoom-in" data-aos-duration="500"><p><span className="material-symbols-outlined me-3">check</span>All Standard Plan Features</p></li>
                                <li data-aos="zoom-in" data-aos-duration="1000"><p><span className="material-symbols-outlined me-3">check</span>Advanced Analytics</p></li>
                                <li data-aos="zoom-in" data-aos-duration="1500"><p><span className="material-symbols-outlined me-3">check</span>Priority Customer Support</p></li>
                                <li data-aos="zoom-in" data-aos-duration="2000"><p><span className="material-symbols-outlined me-3">check</span>AI-Powered Insights</p></li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>

        <section className='plans-include'>
          <div className='container'>
          <div className=''>
     <div className='row justify-content-center'>

         <div className='col-lg-3'>
          <ul className='border-paid'>
         <li>
                <div className="done-item">
               
                <p><strong>All Plans Include</strong>	</p>
                </div>
              </li>

              <li>
                <div className="done-item">
                <span className="material-symbols-outlined">done</span>
                <p>Ticket Support</p>
                </div>
              </li>
              <li>
                <div className="done-item">
                <span className="material-symbols-outlined">done</span>
                <p>24/7 Live Chat Support</p>
                </div>
              </li>
              <li>
                <div className="done-item">
                <span className="material-symbols-outlined"> done</span>
                <p>Migration Assistance</p>
                </div>
              </li> 
              </ul>   
           </div>

          <div className='col-lg-3'>
          <ul className='border-paid'>
          <li>
              <div className="done-item">
              <p></p>
              </div>
          </li>
            <li>
              <div className="done-item">
              <span className="material-symbols-outlined">done</span>
              <p>Permission Management</p>
              </div>
            </li>
            <li>
              <div className="done-item">
              <span className="material-symbols-outlined">done</span>
              <p>Personalized Branding</p>
              </div>
            </li>
            <li>
              <div className="done-item">
              <span className="material-symbols-outlined">done</span>
              <p>Detailed Reports</p>
              </div>
            </li>
            </ul>
          </div>
          <div className='col-lg-3'>
          <ul >
            <li>
              <div className="done-item">
              <p></p>
              </div>
          </li>
              <li>
                <div className="done-item">
                <span className="material-symbols-outlined">done</span>
                <p>Notifications</p>
                </div>
              </li>
              <li>
                <div className="done-item">
                <span className="material-symbols-outlined">done</span><p>Single Sign On</p>
                </div>
              </li>
              <li>
                <div className="done-item">
                  <span className="material-symbols-outlined">done</span>
                  <p>Google & Microsoft Logins</p>
                </div>
              </li>
    </ul>
  </div>
</div>
</div>
          </div>
        </section>
 

  <section className="question-blocker">
             <div className="container">
                <div className="question-head text-center">
                    <h2>Frequently asked questions</h2>
                    <p>Everything you need to know about SuccessDesk. Can’t find the answer you are looking for?<a href=""> Please chat with our support team.</a></p>
                </div>
               <div className="row mt-sm-5 mt-2 position-relative">
                <div className="blur-body">
                    
                </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="question-body">
                            <div>
                            <h5>Is there a free trial available?</h5>
                            <p className="py-2">Yes, we offer an early access trial for 30 days. This allows you to experience the full range of our features before committing to a subscription.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="question-body">
                            <div>
                            <h5>How does billing work?</h5>
                            <p className="py-2">Billing is done on a monthly or annual basis, depending on your chosen plan.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="question-body">
                            <div>
                            <h5>Can I change my plan later?</h5>
                            <p className="py-2">Absolutely! You can upgrade, downgrade, or switch between monthly and annual plans at any time.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="question-body">
                            <div>
                            <h5>Do you offer an annual plan?</h5>
                            <p className="py-2">Yes, we offer an annual subscription plan. For the annual plan, you pay a discounted amount instead of the full price. If you decide to change plans during a billing cycle, we'll pro-rate and provide you with the adjusted price.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="question-body">
                            <div>
                            <h5>Can I switch from a monthly to an annual plan (or vice versa)?</h5>
                            <p className="py-2">Certainly! You have the flexibility to switch between monthly and annual plans at any time. Adjustments will be pro-rated and reflected in your next billing cycle.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="question-body">
                            <div>
                            <h5>What is your cancellation policy?</h5>
                            <p className="py-2">You can cancel your subscription at any time, and your subscription will continue until the end of the prepaid period. Please see more details about our terms by <a href='https://swizzleup.com/termsandconditions'>clicking here.</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="question-body">
                            <div>
                            <h5>What kind of support does SuccessDesk you offer?</h5>
                            <p className="py-2">We provide 24/7 support through a dedicated customer support team. Assistance is available through tickets, live chat, and email.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="question-body">
                            <div>
                            <h5>Can I customize SuccessDesk with my branding?</h5>
                            <p className="py-2">Yes, you can personalize SuccessDesk with your own branding, creating a cohesive and professional environment for your team and clients. We provide options for branded logos, emails and domains.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="question-body">
                            <div>
                            <h5>Can I integrate SuccessDesk with other tools or third-party APIs?</h5>
                            <p className="py-2">Yes, SuccessDesk supports custom on-demand integrations, allowing you to seamlessly connect with other tools and proprietary APIs, tailoring your workflow to specific needs. Please note that additional costs may apply for custom development and integration services.</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-12 col-12">
                        <div className="d-flex justify-content-center load-btn pt-4">
                            <button className="btn btn-outline-primary">Load More</button>
                        </div>
                        
                    </div>  */}
                    
               </div>
              
            </div> 

        </section>
  
  </>
  )
}

export default Pricing