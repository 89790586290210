import React, { useEffect, useState } from 'react'
import AOS from 'aos'
import contactbanner from '../Assets/Images/conatct-banner.jpg';
import contactinfo from '../Assets/Images/contact-info.jpg';
import {useForm} from 'react-hook-form';
import md5 from "md5";
import axios from "axios";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet-async";
import ReCAPTCHA from "react-google-recaptcha";

function ContactUs() {
   
  const contactFormURL = process.env.REACT_APP_API_URL+"api/submit";
  const countryCodeURL = process.env.REACT_APP_API_URL+"api/getcountrycode";
  const apiKey = process.env.REACT_APP_API_KEY;
  const recaptchaRef = React.useRef();
  const [countryCode,setCountryCode] = useState([]);
  useEffect(() => {

    AOS.init();
    AOS.refresh();
    // window.scrollTo({top: 0, left: 0, behavior: 'instant'});
    getCountryCode();
  }, []);
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    setValue,
  } = useForm();

  const timestamp = Date.now();
  const key= timestamp+apiKey;
  const encryptedBackendKey = md5(key)
  const data = {
    'timestamp': timestamp 
  };

  const headers = {
    'X-Secret-Key': encryptedBackendKey,
     "Cache-Control": "no-cache",
     "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*",
  };

  const getCountryCode = async ()=>{
    try {
    //   setIsLoading(true);
      const response = await axios.post(countryCodeURL,data ,{ headers });
        // console.log('Response:', response.data);
        if(response.data.status === 1){
            // console.log(response.data.countryCode);
            setCountryCode(response.data.countryCode);
           // console.log(response.data.countryCode);
        //   setIsLoading(false);
        }else{
          Swal.fire({
            icon: 'error',
            title: response.data.message,
            // text: error,
            timer: 3000,
          })
        }
    } catch (error) {
        console.error('Error:', error);
    }
  }
  const handleInputChange = (name, value) => {
    setValue(name, value); // Update the form value
    trigger(name); // Trigger validation for the field
  };

  const formSubmit = async (formData) => {
    const token = await recaptchaRef.current.executeAsync();
    try {
      const data = {...formData, ...{timestamp}, ...{token}}
      const response = await axios.post( contactFormURL, data,{headers});

    //   console.log(data);
      // console.log(response.data);
      if (response.data.status === 1) {
        Swal.fire({
          icon: "success",
          title: response.data.message,
          // text: 'Thank you for your submission.',
          timer: 3000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error submitting the form",
          text: response.data.message,
          timer: 3000,
        });
      }

      reset();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error submitting the form",
        text: error,
        timer: 3000,
      });
      console.error("Error submitting the form:", error);
    }
  };
// Finding the item with id equal to 1 for default selection
  const defaultSelectedItem = 214;

  // State to store the selected item id
  const [selectedItemId, setSelectedItemId] = useState(defaultSelectedItem ? defaultSelectedItem : '');

  // Handler for select change
  const handleSelectChange = (value) => {
      //alert("dsdds");
    const itemId = parseInt(value);
      //alert(itemId);
    setSelectedItemId(itemId);
  };
  return (
    <>
    <Helmet>
        <title>SuccessDesk | Contact Us</title>
        <meta
          name="description"
          content="Your feedback and inquiries are invaluable to us, and we look forward to connecting with you. We're here to listen, assist, and collaborate."
        />
        <meta
          name="keywords"
          content="SuccessDesk Contact, SDK, Swizzle Innovations, Your feedback, Inquiries, Email, Phone, Share your insights, Streamline Your Success, Hear from you, Listen, Assist, collaborate"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Contact Us | SuccesDesk" />
        <meta
          property="og:description"
          content="Your feedback and inquiries are invaluable to us, and we look forward to connecting with you. We're here to listen, assist, and collaborate."
        />
      </Helmet>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_SITE_KEY}
      />
    <section className="contact-banner-wrapper  banner-top">   
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-12" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="1000">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-12 order-1 order-md-0">
                            <div className="contact-banner-content">
                                <h1>We’d Love To Hear From You</h1>
                                <p className="m-0">Your feedback is invaluable to us, and we look forward to connecting with you.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 order-0 order-md-1 mt-md-0 mt-4">
                            <img src={contactbanner} className="img-fluid" alt="Share Your Feedback and Inquiries with Us"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    </section>
           
        <section className="contact-blocker">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    {/* <div className="col-lg-4 col-md-7 col-12">
                        <div className="card-body">
                            <div className="minus-middle">
                                <span className="material-symbols-outlined">location_on</span>
                            </div>
                            <div className="contact-info">
                                <h4>Office</h4>
                                <p>Come and say hello at our office</p>
                                <a href="https://maps.app.goo.gl/xHXbauBBpTF9DuDd6" target='_blank' className="btn btn-outline-primary">View on Google Maps</a>
                            </div>

                        </div>

                    </div>
                    <div className="col-lg-4 col-md-7 col-12">
                        <div className="card-body email-body">
                            <div className="minus-middle">
                                <span className="material-symbols-outlined">mail</span>
                            </div>
                            <div className="contact-info">
                                <h4>Email</h4>
                                <p>Our friendly team is here to help</p>
                                <a href="mailto:support@successdesk.com" className="btn btn-primary">support@successdesk.com</a>
                            </div>

                        </div>
                    </div> */}
                    {/* <div className="col-lg-4 col-md-7 col-12">
                        <div className="card-body">
                            <div className="minus-middle">
                                <span className="material-symbols-outlined">call</span>
                            </div>
                            <div className="contact-info">
                                <h4>Phone</h4>
                                <p>Mon - Fri from 8am to 9pm EST</p>
                                <a href="tel:+91 987 589 8472" className="btn btn-outline-primary">+91 987 589 8472</a>
                            </div>

                        </div>
                    </div> */}

                    {/* <div className="col-lg-6 col-md-10 col-12">
                        <img src={contactinfo} className="img-fluid form-img" alt="Share Your Insights"  data-aos="fade-up" data-aos-anchor-placement="center-bottom"/>
                    </div> */}
                   
                    <div className="col-lg-6 col-md-10 col-12">
                        <div className="form-body">
                            <h3>Share your insights</h3>
                            <p>We're here to listen, assist, and collaborate.</p>
                            <form onSubmit={handleSubmit(formSubmit)}>
                            <div className="form-group mt-4">
                                <label className="custom-field one position-relative">
                                    <input type="text" className="form-control" placeholder="" {...register('name',{
                                                        required: "Please enter name",
                                                })} onChange={(e) => handleInputChange('name', e.target.value)}/>
                                    <span className="placeholder">Name</span>
                                  </label>
                                  {errors.name && <span className="error">{errors.name.message}</span>}
                                  <label className="custom-field one position-relative mt-3">
                                    <input type="text" className="form-control" placeholder=""{...register("email", {
                                        required: "Please enter email",
                                        pattern: {
                                            value:
                                            /^\b[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,4}\b$/,
                                            message: "Please enter a valid email address",
                                        },
                                        })}  onChange={(e) => handleInputChange('email', e.target.value)}/>
                                        <span className="placeholder">Email</span>
                                  </label>
                                  {errors.email && <span className="error">{errors.email.message}</span>}
                                  <label className="custom-field select-body one position-relative mt-3">
                                    <input type="text" className="form-control" placeholder=""{...register("phone",{
                                                        required: "Please enter phone number",
                                                        pattern: {
                                                        //   value:  /^(1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/,
                                                        //   value:  /^[0-9]{10,12}$/,
                                                          value:  /^(?:\+\d{1,3}[. -]?)?(\([2-9]\d{2}\)|[2-9]\d{2})[. -]?[2-9]\d{2}[. -]?\d{4}$/,
                                                          message: 'Please enter a valid phone number',
                                                        },
                                                    })}  onChange={(e) => handleInputChange('phone', e.target.value)}/>
                                    <span className="placeholder">Phone Number</span>
                                        <span className='select-blocker'>
                                            <select className="form-select sel-box" value={selectedItemId}  {...register("country_code")} onChange={(e) => handleSelectChange(e.target.value)}>
                                                {(countryCode?.length > 0) ? (
                                                    countryCode.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                         {item.country_code}
                                                        </option>
                                                    ))
                                                ) : null}
                                             </select>
                                        </span>
                                     </label>
                                  {errors.phone && <span className="error">{errors.phone.message}</span>}
                                  <label className="custom-field one position-relative mt-3">
                                    <textarea name="" id="input" className="form-control mb-" rows="4" placeholder=""{...register('text',{
                                                        required: "Please enter the description",
                                                })}  onChange={(e) => handleInputChange('text', e.target.value)}></textarea> 
                                    <span className="placeholder">Go ahead, We are listening...</span>               
                                  </label>
                                  {errors.text && <span className="error">{errors.text.message}</span>}
                                 
                            </div>
                            <button className="btn btn-primary mt-4" type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                    

                </div>

            </div>
        </section>

        <section className="question-blocker">
            {/* <div className="container">
                <div className="question-head text-center">
                    <h2>Frequently asked questions</h2>
                    <p>Everything you need to know about SuccessDesk. Can’t find the answer you are looking for?<a href=""> Please chat with our support team.</a></p>
                </div>
               <div className="row mt-sm-5 mt-2 position-relative">
                <div className="blur-body">
                    
                </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="question-body">
                            <div>
                            <h5>Is there a free trial available?</h5>
                            <p className="py-2">No, currently we aren't providing a free trial. But we are providing a free demo. All services and features of successDesk will be demonstrated on this free demo.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="question-body">
                            <div>
                            <h5>Can i change my plan later?</h5>
                            <p className="py-2">Of course, our pricing scales with our company. Chat wih our friendly team to find a plan that suits your needs.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="question-body">
                            <div>
                            <h5>What is your cancellation policy?</h5>
                            <p className="py-2">We understand that times change. you can cancel your plan at any time and we’ll refund you the difference already paid.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="question-body">
                            <div>
                            <h5>How does billing work?</h5>
                            <p className="py-2">Plan as per workspace not  per account. You can upgrade one workspace and still have any number of free workspaces.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="question-body">
                            <div>
                            <h5>How can i change my account email?</h5>
                            <p className="py-2">You can change the email address and other profile information from the profile edit section under settings tab.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="question-body">
                            <div>
                            <h5>How can I reset my password?</h5>
                            <p className="py-2">Resetting your password is easy. Simply click on the "Forgot Password" link on the login page, and you'll receive an email with instructions on how to create a new password.</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-12 col-12">
                        <div className="d-flex justify-content-center load-btn pt-4">
                            <button className="btn btn-outline-primary">Load More</button>
                        </div>
                        
                    </div> 
                    
               </div>
              
            </div> */}

        </section>
    
    </>
  )
}

export default ContactUs