import React, { useState,useEffect } from 'react'
import {useForm} from 'react-hook-form';
import Swal from "sweetalert2";
import md5 from "md5";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';

function DemoModal(props) {
    // console.log(props.isModalVisible);
    // console.log(props.hideModal);
    const countryCodeURL = process.env.REACT_APP_API_URL+"api/getcountrycode";
  const contactFormURL = process.env.REACT_APP_API_URL+"api/demosubmit";
  const apiKey = process.env.REACT_APP_API_KEY;  
  const [countryCode,setCountryCode] = useState([]);
    const timestamp = Date.now();
  const key= timestamp+apiKey;
  const data = {
    'timestamp': timestamp 
  };
  const encryptedBackendKey = md5(key)
   const headers = {
    'X-Secret-Key': encryptedBackendKey,
     "Cache-Control": "no-cache",
     "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*",
  };
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        trigger,
        setValue,
    } = useForm();

    useEffect(() => {
        reset();
        getCountryCode();
      }, [props.isModalVisible]);

      const handleInputChange = (name, value) => {
        setValue(name, value); // Update the form value
        trigger(name); // Trigger validation for the field
      };
    

      const getCountryCode = async ()=>{
        try {
        //   setIsLoading(true);
          const response = await axios.post(countryCodeURL,data ,{ headers });
            // console.log('Response:', response.data);
            if(response.data.status === 1){
                // console.log(response.data.countryCode);
                setCountryCode(response.data.countryCode);
               // console.log(countryCode);
            //   setIsLoading(false);
            }else{
              Swal.fire({
                icon: 'error',
                title: response.data.message,
                // text: error,
                timer: 3000,
              })
            }
        } catch (error) {
            console.error('Error:', error);
        }
      }
    const requestSubmit = async(requestData) =>{
            
            console.log(requestData);
            try{
                 const data = {...requestData, ...{timestamp}}
                 const response = await axios.post( contactFormURL, data,{headers});

                if (response.data.status === 1) {
                    Swal.fire({
                      icon: "success",
                      title: response.data.message,
                      // text: 'Thank you for your submission.',
                      timer: 3000,
                    });
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Error submitting the form",
                      text: response.data.message,
                      timer: 3000,
                    });
                  }
                  props.hideModal()
                  reset();
            } catch (error) {
              Swal.fire({
                icon: "error",
                title: "Error submitting the form",
                text: error,
                timer: 3000,
              });
              console.error("Error submitting the form:", error);
            }
            

        }
    
    // Finding the item with id equal to 1 for default selection
  const defaultSelectedItem = 214;

  // State to store the selected item id
  const [selectedItemId, setSelectedItemId] = useState(defaultSelectedItem ? defaultSelectedItem : '');

  // Handler for select change
  const handleSelectChange = (value) => {
      //alert("dsdds");
    const itemId = parseInt(value);
      //alert(itemId);
    setSelectedItemId(itemId);
  };
  return (
    <Modal
            show={props.isModalVisible}
            onHide={props.hideModal}
            // size="lg"
            className="modal"
            centered
        >
                {/* <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"> */}

                {/* <div className="modal-dialog modal-dialog-centered"> */}
                    
                <div className="modal-content demo-modal">
                <form onSubmit={handleSubmit(requestSubmit)}>
                    <div className="modal-header">
                        <div className="modal-backgrnd px-1 pt-1">
                            <a type="" className="" data-bs-dismiss="modal" aria-label="Close" onClick={props.hideModal}><span className="material-symbols-outlined">cancel</span></a>
                        </div>
                    </div>
                    <div className="modal-body p-4">
                        <h4>Secure your spot for exclusive early access!</h4>
                        <p>Enter your details now and be among the first to explore SuccessDesk.</p>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <label className="custom-field one position-relative">
                                    <input type="text" className="form-control" placeholder=""{...register('name',{
                                                        required: "Please enter name",
                                                })} onChange={(e) => handleInputChange('name', e.target.value)}/>
                                    <span className="placeholder">Name</span>
                                  </label>
                                  {errors.name && <span className="error">{errors.name.message}</span>}
                                  <label className="custom-field one position-relative mt-3">
                                    <input type="text" className="form-control" placeholder=""{...register("email", {
                                        required: "Please enter email",
                                        pattern: {
                                            value:
                                            /^\b[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,4}\b$/,
                                            message: "Please enter a valid email address",
                                        },
                                        })} onChange={(e) => handleInputChange('email', e.target.value)}/>
                                    <span className="placeholder">Email</span>
                                  </label>
                                  {errors.email && <span className="error">{errors.email.message}</span>}
                                   <label className="custom-field select-body one position-relative mt-3">
                                    <input type="text" className="form-control" placeholder=""{...register("phone", {
                                                        required: "Please enter phone number",
                                                        pattern: {
                                                        //   value:  /^(1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/,
                                                        //   value:  /^[0-9]{10,12}$/,
                                                          value:  /^(?:\+\d{1,3}[. -]?)?(\([2-9]\d{2}\)|[2-9]\d{2})[. -]?[2-9]\d{2}[. -]?\d{4}$/,
                                                          message: 'Please enter a valid phone number',
                                                        },
                                                    })}  onChange={(e) => handleInputChange('phone', e.target.value)}/>
                                    <span className="placeholder">Phone Number</span>
                                        <span className='select-blocker'>
                                            <select className="form-select sel-box" value={selectedItemId}  {...register("country_code")} onChange={(e) => handleSelectChange(e.target.value)} >
                                                {(countryCode?.length > 0) ? (
                                                    countryCode.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                         {item.country_code}
                                                        </option>
                                                    ))
                                                ) : null}
                                             </select>
                                        </span>
                                     </label>
                                  {errors.phone && <span className="error">{errors.phone.message}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-center">
                        <button type="submit" className="btn btn-primary">Submit Request</button>
                    </div>
                    </form>
                </div>
            {/* </div> */}
        {/* </div> */}
        </Modal>
  )
}

export default DemoModal