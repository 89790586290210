import React, { useEffect,useState,Fragment } from 'react'
import bannera from '../Assets/Images/banner-a.png';
import bannerleft from '../Assets/Images/banner-left.png';
import footerlogo from '../Assets/Images/footer-logo.png';
import banner_bg_a from '../Assets/Images/banner-bg-a.png';
import banner_bg_b from '../Assets/Images/banner-bg-b.png';
import swizzlelogo from '../Assets/Images/swizzle-logo.svg';
import fflogo from '../Assets/Images/ff-logo-footer.svg';
import sdklogo from '../Assets/Images/sdk-logo-a.svg';
import { Link } from 'react-router-dom';
function Footer(props) {

   var succesDeskUrl = process.env.REACT_APP_SUCCESSDESK_URL;
        const [formattedString, setFormattedString] = useState('');

        useEffect(() => {
        // Retrieve the item from localStorage
        const stringFromLocalStorage = localStorage.getItem('sourceParamssdk');

        if (stringFromLocalStorage) {
          try {
            // Parse the string into an object
            const parsedObject = JSON.parse(stringFromLocalStorage);

            // Convert the object to a string with key-value pairs
            const keyValueString = Object.entries(parsedObject)
              .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
              .join('&');

            // Set the formatted string to the state variable
            setFormattedString(keyValueString);
          } catch (error) {
            console.error('Error parsing JSON from localStorage:', error);
          }
        }
      }, []);
        if(formattedString!=''){
            succesDeskUrl = succesDeskUrl+'&'+formattedString;
        }
  return (
    <footer>
    {/* <div className="container">
      <div className="row">
          <div className="col-md-12 col-12">
              <div className="footer-box-align px-lg-5 px-0">
                  <div className="footer-banner">
                      <img className="banner-a" src={bannera} alt="Banner"/>
                      <div className="text-center">
                          <h6>Streamline Your Success</h6>
                          <h2 className="">What are you waiting for?</h2>
                          <a className="btn btn-primary mt-2" target='_blank' href={succesDeskUrl}>Try SuccessDesk</a>
                      </div>
                      <img className="banner-b" src={bannerleft} alt="Banner"/>
                  </div>
              </div>
          </div>
      </div>
   
    <div className="bottom-menu mx-lg-5 mx-lg-0 pb-2">
    <div className="row align-items-center ">
      <div className="col-md-5 col-12">
          <div className="footer-logo"><a href="/">
              <img src={footerlogo} className="img-fluid" alt="SuccessDesk Logo"/></a>
          </div>
      </div>
      <div className="col-md-7 col-12">
          <ul className="footer-menu">
            <li><Link className="bottom-menu-icons ms-md-4 ms-0" to='/'>Home</Link></li>
            <li><Link className="bottom-menu-icons ms-md-4 ms-0" to='/solutions'>Solutions</Link></li>
            <li><Link className="bottom-menu-icons ms-md-4 ms-0" to='/contactus'>Contact Us</Link></li>
            <li><a className="bottom-menu-icons ms-md-4 ms-0" onClick={props.openModal}>Request Early Access</a></li>
          </ul>
       
      </div>
  </div>
  </div>

  <div className="row px-md-5 mt-4 pb-4">
      <div className="col-xl-6 col-md-7 col-12 p-0">
          <ul className="footer-menu-polices px-lg-5 px-0">
            <li><a className="bottom-menu-icons " href='https://demoserver22.icwares.com/swizzle_innovations/dev/privacypolicy'>Privacy</a></li>
            <li><a className="bottom-menu-icons ms-md-4 ms-0" href="https://demoserver22.icwares.com/swizzle_innovations/dev/termsandconditions">Terms and conditions</a></li>
          
          </ul>
       
      </div>
         <div className="col-xl-6 col-md-5 col-12">
              <div className="footer-icons">
                  <ul className="d-flex justify-content-end">
                      <li><a className="bottom-menu-icons ms-lg-4 ms-md-3 ms-3 me-md-0 me-sm-3 me-1" href="https://twitter.com/swizzleinnovate" target="_blank"><i className="fa-brands fa-x-twitter"></i></a></li>
                      <li><a className="bottom-menu-icons ms-lg-4 ms-md-3 ms-3 me-md-0 me-sm-3 me-1" href="https://www.facebook.com/swizzleinnovations" target="_blank"><i className="fa-brands fa-facebook"></i></a></li>
                      <li><a className="bottom-menu-icons ms-lg-4 ms-md-3 ms-3 me-md-0 me-sm-3 me-1" href="https://www.instagram.com/swizzleinnovations/" target="_blank"><i className="fa-brands fa-instagram"></i></a></li>
                      <li><a className="bottom-menu-icons ms-lg-4 ms-md-3 ms-3" href="https://www.linkedin.com/company/swizzleinnovations/about/" target="_blank"><i className="fa-brands fa-linkedin"></i></a></li>
                  </ul>
              </div>
         </div>
        </div>
    </div> */}
     <div className="footer">
                <div className="footer-line">
                    <div className="container">
                        <div className="row">
                            <div className="footer-box-align">
                                <div className="footer-banner shadow">
                                <img className="banner-a" src={banner_bg_a} alt="Banner"/>
                                    <div className="text-center">
                                        <h6>Streamline Your Workflow</h6>
                                        <h2 className="">Ready to move your team to <br /><span>SuccessDesk</span>?</h2>
                                        <button className="btn btn-primary btn-align" onClick={props.openModal}>Request Early Access</button>
                                    </div>
                                    <img className="banner-b" src={banner_bg_b} alt="Banner"/>
                                </div>
                            </div>
                        </div>



                            <div className="footer-menu">
                                <div className="row">


                                    <div className="col-md-12 col-lg-3 justify-content-center align-items-center d-flex">
                                    <a href="/"><img src={footerlogo} className="img-fluid" alt="SuccessDesk Logo"/></a>
                                          
                                    </div>
                                  
                                    <div className="col-md-3 col-lg-2">
                                        <h6>Product</h6>
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/solutions">Solutions</Link></li>
                                            <li><Link to="/pricing">Pricing</Link></li>
                                            <li><Link to="/contactus">Contact</Link></li>
                                        </ul>
                                    </div>

                                    <div className="col-md-3 col-lg-2">
                                        <h6>Legal</h6>
                                        <ul>
                                        <li><a target="_blank" href="https://swizzleup.com/security-compliance">Security Compliance</a></li>
                                            <li><a target="_blank" href="https://swizzleup.com/termsandconditions">Terms of Use</a></li>
                                            <li><a target="_blank" href="https://swizzleup.com/privacypolicy">Privacy Policy</a></li>
                                            {/* <li><a href="/#">Cancellation Policy</a></li> */}

                                        </ul>
                                    </div>


                                    <div className="col-md-3 col-lg-3">
                                        <h6>Solutions</h6>
                                        <ul>
                                            <li><Link to="/solutions#projectManage" onClick={props.ScrollToSection}>Project Management</Link></li>
                                            <li><Link to="/solutions#enhanceProject" onClick={props.ScrollToSection}>Customer Success</Link></li>
                                            <li><Link to="/solutions#business" onClick={props.ScrollToSection}>Business Transformation</Link></li>
                                        </ul>
                                    </div>


                                    <div className="col-md-3 col-lg-2">

                                        <div className='row'>
                                            <div className='col-md-12'>
                                            <h6>Swizzle Products </h6>
                                            <ul className='mt-1 mb-0'>
                                                <li><a className='pdt-icons  me-lg-4 me-md-2 me-3'target="_blank" href='https://fileflow.app/'><img src={fflogo} className="img-fluid" alt="SuccessDesk Logo"/></a></li>
                                                <li><a className='pdt-icons  me-lg-4 me-md-2 me-3'target="_blank" href='/'><img src={sdklogo} className="img-fluid sdklogo" alt="SuccessDesk Logo"/></a></li>
                                            </ul>
                                            </div>
                                            <div className='col-md-12'>
                                            <h6>Connect With Us</h6>
                                        <div className="footer-icons">
                                        <ul className="d-flex mt-2">
                                            
                                            <li><a className="bottom-menu-icons  me-lg-4 me-md-2 me-3" href="https://www.facebook.com/swizzleinnovations" target="_blank"><i className="fa-brands fa-facebook"></i></a></li>
                                            <li><a className="bottom-menu-icons  me-lg-4 me-md-2 me-3" href="https://www.instagram.com/swizzleinnovations" target="_blank"><i className="fa-brands fa-instagram"></i></a></li>
                                            {/* <li><a className="bottom-menu-icons  me-lg-4 me-md-2 me-3" href="/#"><i className="fa-brands fa-youtube" target="_blank"></i></a></li> */}
                                            <li><a className="bottom-menu-icons  me-lg-4 me-md-2 me-3" href="https://twitter.com/swizzleinnovate" target="_blank"><i class="fa-brands fa-x-twitter" ></i></a></li>
                                            <li><a className="bottom-menu-icons  me-lg-4 me-md-2 me-3" href="https://www.linkedin.com/company/swizzleinnovations/" target="_blank"><i className="fa-brands fa-linkedin"></i></a></li>
                                        </ul>
                                        </div>
                                            </div>
                                        </div>



                                   



                                    </div>


                                </div>
                            </div>




                        </div>
                    </div>
                    <div className="container">
                        <div className="bottom-menu mt-2 pb-2">
                            <div className="row ">
                              

                                <div className="col-md-6 col-lg-6">
                                    <div class="product">
                                        <a className='d-flex' target="_blank" href="https://swizzleup.com/">
                                          <img className="img-fluid " src={swizzlelogo} alt="logo-fileflow" />
                                          <p>A Product of Swizzle Innovations</p>
                                      </a>
                                   </div>
                                </div>

                                <div className="col-md-6 col-lg-6">
                                  <p class="pld"><span>&#169;</span>{new Date().getFullYear()} | Swizzle Innovations Pvt. Ltd. All Right Reserved</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
</footer>


  )
}

export default Footer