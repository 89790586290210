import React, { useEffect, useState } from 'react'
import succesdesklogo from '../Assets/Images/successdesk-logo.svg';
import {NavLink, Link, Router } from 'react-router-dom';
function Navbar(props) {
    // console.log(props);
    const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
    <header>

    <nav className="navbar  navbar-expand-lg navbar-light  nav-style pt-2 pb-2">
        <div className="container-fluid px-xl-5 px-sm-3 px-2">
            <a className={`navbar-brand ${scrolling ? 'active' : ''}`} href="/"><img src={succesdesklogo} alt="SuccessDesk Logo"/></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
            
            <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                <div className="menu">
                    <ul className="navbar-nav  mb-2 mb-lg-0">
                        <li className="nav-item me-lg-4 me-0">
                            <NavLink to='/' className="nav-link" >Home</NavLink>
                        </li>
                        <li className="nav-item me-lg-4 me-0">
                            <NavLink to='/solutions' className="nav-link" aria-current="page" >Solutions</NavLink>
                        </li>
                        <li className="nav-item me-lg-4 me-0">
                            <NavLink to='/pricing' className="nav-link " aria-current="page" >Pricing</NavLink>
                        </li>
                        <li className="nav-item me-lg-4 me-0">
                            <NavLink to='/contactus' className="nav-link " aria-current="page" >Contact Us</NavLink>
                        </li>
                        <li className="nav-item me-lg-4 me-0">
                            <button className="btn btn-primary d-flex align-items-center" onClick={props.openModal}>Request  Early Access</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>

</header>
</>
  )
}

export default Navbar