import React, { useEffect,useState } from 'react'
import bannerimg from '../Assets/Images/banner-ring.png';
import avatarleft from '../Assets/Images/avatar-left.png';
import avatarrignt from '../Assets/Images/avatar-right.png';
import bannerbottom from '../Assets/Images/banner-bottom.png';
import userimg from '../Assets/Images/users-img.png';
import clientservice from '../Assets/Images/client-service.png';
import avatargroup from '../Assets/Images/avatar-group.png';
import graph from '../Assets/Images/graph.png';
import {useForm} from 'react-hook-form';
import notification from '../Assets/Images/notification.png';
import md5 from "md5";
import axios from "axios";
import Swal from "sweetalert2";
import AOS from 'aos';
import { Helmet } from "react-helmet-async";
import projectflow from '../Assets/Images/projectflow.png';
function Home(props) {
    
  
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
      }, []);
  


      const {
            register,
            handleSubmit,
            formState: { errors },
            reset
        } = useForm();
        var succesDeskUrl = process.env.REACT_APP_SUCCESSDESK_URL;
        const [formattedString, setFormattedString] = useState('');

        useEffect(() => {
        // Retrieve the item from localStorage
        const stringFromLocalStorage = localStorage.getItem('sourceParamssdk');

        if (stringFromLocalStorage) {
          try {
            // Parse the string into an object
            const parsedObject = JSON.parse(stringFromLocalStorage);

            // Convert the object to a string with key-value pairs
            const keyValueString = Object.entries(parsedObject)
              .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
              .join('&');

            // Set the formatted string to the state variable
            setFormattedString(keyValueString);
          } catch (error) {
            console.error('Error parsing JSON from localStorage:', error);
          }
        }
      }, []);
        if(formattedString!=''){
            succesDeskUrl = succesDeskUrl+'&'+formattedString;
        }

  return (
    <>
     <Helmet>
        <title>SuccessDesk | Home</title>
        <meta
          name="description"
          content="SuccessDesk optimizes your team and client collaboration workflow, ensuring a seamless process that elevates your customer success to new heights."
        />
        <meta
          name="keywords"
          content="SuccessDesk, SDK, Swizzle Innovations, Client collaboration workflow, effortless interaction, Streamline Your Success"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="SuccessDesk | Effortlessly Streamline Tasks, Boost Productivity" />
        <meta
          property="og:description"
          content="SuccessDesk optimizes your team and client collaboration workflow, ensuring a seamless process that elevates your customer success to new heights."
        />
      </Helmet>
        <section className="banner-wrapper banner-top">   
            <div className="container-fluid px-xl-5 px-lg-4 px-3">
                <div className="row justify-content-center text-center pt-4">
                  <div className="col-md-12 col-12">
                    <div className="banner-content">
                        <small className="pt-3 m-0 ">Introducing SuccessDesk</small>
                    
                            <h1 className="p-2 m-0 banner-heading">Streamline Your <span>Workflow
                                <img src={bannerimg} className="img-fluid ring-img" alt="ring-bowl"/>
                            </span></h1>
                            
                      <div className="position-relative">
                        {/* <p>SuccessDesk allows you to effortlessly streamline tasks and boost productivity with automated CRM solutions.</p> */}
                        <p>SuccessDesk optimizes your team and client collaboration workflow, ensuring a seamless process that elevates your customer success to new heights.</p>
                        <img src={avatarleft} className="img-fluid left-avatar vert-move" alt="avatar"/>
                      </div>
                      <div className="banner-input pt-4">
                        
                        <span className="btn-blocker">
                        <button className="btn btn-primary d-flex align-items-center ms-sm-3 ms-0"  onClick={props.openModal}>Request Early Access<span className="material-symbols-outlined ms-2"> arrow_forward</span></button>
                        </span>
                        <img src={avatarrignt} className="img-fluid right-avatar vert-move" alt="avatar"/>
                      </div>
                    </div>
                </div>
            </div>
        </div>  
        <div className="files-section">
            <img src={bannerbottom} className="img-fluid" alt="Streamline Workflow"/>
        </div> 
    </section>

    {/* banner ends */}

    {/* services starts */}

    <section className="service-blocker">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-12">
                    <div className="project-card" data-aos="fade-up" data-aos-duration="1800">
                        <div className="inner-tips pb-2 ms-3">
                            {/* <p className="m-0 mt-3"><span className="material-symbols-outlined me-2">task_alt</span>What you need is here</p> */}
                        </div>
                        <h2 className="pb-lg-4 pb-2 ms-3">Manage all your<br/> projects in one place.</h2>
                        <img src={userimg} className="" alt="Manage Projects"/>
                    </div>
                </div>
                <div className="col-lg-6 col-12">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="client-service-card" data-aos="fade-up" data-aos-duration="1800">
                                <div className="inner-tips pb-2">
                                    <p className="m-0"><span className="material-symbols-outlined me-2">list_alt</span>Customer portal designed for effortless interaction.</p>
                                </div>
                                    <img src={clientservice} className="img-fluid mt-3" alt="My Services"/>
                                
                            </div>
                        </div>
                        <div className="col-lg-12 ">
                            <div className="avatar-teamates mt-4" data-aos="fade-up" data-aos-duration="1800">
                                <div className="inner-tips pb-2">
                                    <p className="m-0"><span className="material-symbols-outlined me-2">group</span>Collaborate on projects with your team and customers.</p>
                                </div>
                                    <img src={avatargroup} className="img-fluid mt-4" alt="Teammates"/> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>

     {/* service ends */}

    {/* bussiness-section starts */}

    <section className="bussiness-blocker">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 col-12 ">
                   <div className="graph-body " data-aos="fade-up" data-aos-duration="1000">
                    <img src={graph} className="img-fluid" alt="Connecting Companies and Clients"/>
                   </div>
                </div>
                <div className="col-lg-6 col-12 ">
                    <div className="content-wrapper">
                        <div className="p-xl-5  p-sm-4 p-2 mt-sm-0 ">
                            <h2 className='mt-lg-0 mt-2'>We bring companies and clients together.</h2>
                            <p>Discover the magic of working together effortlessly! Our project management and customer success platform makes collaboration a breeze for companies and clients. Elevate your project journey with a platform crafted to unite teams and clients, boosting productivity and nurturing successful partnerships.</p>
                            {/* <a className="btn btn-primary" target='_blank' href={succesDeskUrl}>Try SuccessDesk</a> */}
                            <a className="btn btn-primary" onClick={props.openModal}>Request  Early Access</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-12 order-1 order-lg-0 mt-lg-5 mt-0">
                    <div>
                        <div className="p-xl-5  p-sm-4 p-2 mt-sm-0 mt-3">
                            <h2 className='mt-lg-0 mt-2'>Enhance your projects with additional information.</h2>
                            <p>Simply add comments and attach files, documents, or images to projects and inquiries. The streamlined process makes it easy to share information and gather essential details. Take your interactions to a new level by ensuring that every request is thorough and well-documented.</p>
                            <a className="btn btn-primary" onClick={props.openModal}>Request  Early Access</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-12 order-0 order-lg-1 mt-lg-5 mt-0">
                    <div className="notification-body" data-aos="fade-up" data-aos-duration="1000">
                        <img src={notification} className="img-fluid" alt="Comments and Files"/>
                    </div>
                </div>
            </div>
        </div>
    </section>

     {/* bussiness-section ends */}

    {/* features starts */}

    <section className="features-blocker" >
        <div className="container">
            <div className="features-background" data-aos="fade-up" data-aos-duration="1000">
                <div className="features-text">
                    <div>
                        <h2>And more features to boost your productivity</h2>
                        <h4>Explore a wealth of powerful features designed to streamline your workflow and elevate your efficiency.</h4>
                        <div className="worrk-flow">
                         <p className='cor-a'><span class="material-symbols-outlined me-1">dashboard_customize</span>Project Dashboards</p>
                         <p className='cor-b'><span class="material-symbols-outlined me-1">dynamic_feed</span>Release Planning</p>  
                         <p className='cor-c'><span class="material-symbols-outlined me-1">view_in_ar</span>Board Views</p> 
                         <p className='cor-d'><span class="material-symbols-outlined me-1">timeline</span> Timeline</p> 
                         <p className='cor-e'><span class="material-symbols-outlined me-1">newspaper</span>Media Uploads</p>
                         <p className='cor-f'><span class="material-symbols-outlined me-1">devices</span>Test Cases</p>  
                         <p className='cor-g'><span class="material-symbols-outlined me-1">conveyor_belt</span>Bug Tracking</p>  
                         <p className='cor-h'><span class="material-symbols-outlined me-1">notifications</span>Notifications</p>  
                         <p className='cor-i'><span class="material-symbols-outlined me-1">hourglass_empty</span>Time Tracking </p> 
                         <p className='cor-j'><span class="material-symbols-outlined me-1">pan_tool_alt</span>Support Desk</p>  
                         <p className='cor-k'> <span class="material-symbols-outlined me-1">support_agent</span>Reporting</p>
                         <p className='cor-l'>And many more features.</p>
                          </div>
                          <img src={projectflow} className="img-fluid " alt="ring-bowl"/>
                    </div>
                </div>
            </div>

        </div>

    </section>
    </>
  )
}

export default Home