import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home';
import Solutions from './Pages/Solutions';
import Pricing from './Pages/Pricing';
import ContactUs from './Pages/ContactUs';
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';
import DemoModal from './Component/DemoModal';
import { Route,  Routes, Navigate, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useState,useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from "axios";
import crypto from 'crypto-js';
function App() {
  /*** for marketing******/

  // Function to parse query parameters from URL
  const getQueryParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const params = {};
    for (let param of queryParams.entries()) {
      params[param[0]] = param[1];
    }

    return params;
  };

  // Get query parameters from URL
  const queryParams = getQueryParams();
  // Get query parameters from URL
  const getAppSignature = () => {
    var basepath = process.env.REACT_APP_SOCIALWIRE_URL + 'api/addlogs';
    var apiUrl = `${basepath}?appId=${process.env.REACT_APP_APPID}`;
    queryParams['event_value'] = window.location.origin + window.location.pathname;
    queryParams['event_type'] = 2;
    var sortedParams = {};
    Object.keys(queryParams)
      .sort()
      .forEach((key) => {
        sortedParams[key] = queryParams[key];
      });

    let paramsUrl = '';

    for (const [key, param] of Object.entries(sortedParams)) {
      if (Array.isArray(param)) {
        paramsUrl += `&${key}=${JSON.stringify(param)}`;
      } else {
        paramsUrl += `&${key}=${param}`;
      }
    }
    apiUrl = `${apiUrl}${paramsUrl}`;
    const token = crypto.HmacSHA256(apiUrl, process.env.REACT_APP_APPSECRET).toString(crypto.enc.Hex);
    return token;
  };
  // Get query parameters from URL
  const getparamsData = async () => {
    const getAppSignaturex = getAppSignature();
    const headers = { "APPID": process.env.REACT_APP_APPID, "APPSIGNATURE": getAppSignaturex };
    queryParams['event_value'] = window.location.origin + window.location.pathname;
    queryParams['event_type'] = 2;

    try {
      const response = await axios.post(process.env.REACT_APP_SOCIALWIRE_URL + 'api/addlogs', queryParams, { headers });
      if (response.data.status === 1) {
        console.error("log added");
      } else {

      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const campQueryParams = new URLSearchParams(window.location.search);
  console.log(campQueryParams);
  if (campQueryParams != '') {

    getparamsData();
  }
  // Store the query parameters in localStorage
  localStorage.setItem('sourceParamssdk', JSON.stringify(queryParams));

  // Set a timeout to clear the stored query parameters after 2 days
  setTimeout(() => {
    localStorage.removeItem('sourceParamssdk');
  }, 2 * 24 * 60 * 60 * 1000); // 2 days in milliseconds

  const [isModalVisible, setModalVisibility] = useState(false);
  const {
    reset
  } = useForm();

  const openModal = () => {
    reset();
    setModalVisibility(true);
  };
  const hideModal = () => {
    reset();
    setModalVisibility(false);
  };

  
  const location = useLocation();
  const ScrollToSection = () => {
      const scrollToSelector = location.hash;
      const offset = 100; // Adjust this value according to your needs
  
      if (scrollToSelector) {
          const targetElement = document.getElementById(scrollToSelector.substring(1));
          if (targetElement) {
              const topPos = targetElement.getBoundingClientRect().top + window.pageYOffset - offset;
              window.scrollTo({ top: topPos, behavior: 'smooth' });
          }
      } else {
          window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
      }
  }


  useEffect(() => {
    ScrollToSection();
  }, [location]);

  return (


    <>
      <HelmetProvider>
        {/* <Router> */}
          <DemoModal isModalVisible={isModalVisible} hideModal={hideModal} />
          <Navbar openModal={openModal} />

          <Routes>
            <Route exact path='/' element={<Home openModal={openModal} />} />
            <Route path='/solutions' element={<Solutions openModal={openModal} />} />
            <Route path='/pricing' element={<Pricing openModal={openModal} />} />
            <Route path='/contactus' element={<ContactUs />} />
          </Routes>
          <Footer openModal={openModal} {...{ScrollToSection}}/>
        {/* </Router> */}
      </HelmetProvider>

    </>
  );
}

export default App;
